import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidator } from 'src/app/services/user-validators';
import { tRegistrationInfo } from 'src/app/services/glow.typings';
import { GlowService } from 'src/app/services/glow.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TermsconditionsModalComponent } from 'src/app/components/termsconditions-modal/termsconditions-modal.component';
import { tLoadingElementWithSuccess } from 'src/app/services/app-config.typings';
import { CustomUIConfigService } from 'src/app/services/custom-ui-config.service';
import { TranslateService } from '@ngx-translate/core';
import { FormValidationService } from 'src/app/services/form-validation.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})

export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  viewPassword: boolean = false;
  error = false;
  errorMessages = ['Something went wrong'];
  loadObj: tLoadingElementWithSuccess;

  constructor(private formBuilder: FormBuilder,
    private authService: GlowService,
    private router: Router,
    private modalService: NgbModal,
    private uiConfigService: CustomUIConfigService,
    private translate: TranslateService,
    private formValidationService: FormValidationService
  ) {
    this.registerForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, FormValidator.isEmailValid]],
      password: ['', [Validators.required, FormValidator.isPasswordStrong]],
      confirmPassword: ['', [Validators.required, FormValidator.isPasswordSame]],
    });

  }

  ngOnInit() { }

  togglePassword(viewPassword) {
    this.viewPassword = viewPassword ? false : true;
  }

  openTermsConditions() {
    const termsconditionsRef = this.modalService.open(TermsconditionsModalComponent, {
      scrollable: true,
      centered: true,
      size: 'xl',
    });
    termsconditionsRef.componentInstance.displayForm = false
    termsconditionsRef.componentInstance.title = 'Glow'



  }


  checkFormValidity(formControlName, translateKey?) {
 
    return this.formValidationService.checkFormValidity(this.registerForm, formControlName, translateKey)
  }
  sanitiseDetails(user: tRegistrationInfo) {
    if (user.email) {
      user.email = user.email.toLowerCase();
      user.email = user.email.replace(/\s+/g, ''); //remove spaces at start
      user.email = user.email.replace(/\s+$/, ''); //remove trailing spaces
      user.username = user.email;
    }
    if (user.password) {
      user.password = user.password.replace(/\s+/g, ''); //remove spaces at start
      user.password = user.password.replace(/\s+$/, ''); //remove trailing spaces
    }
    return user;
  }

  doRegister() {
    if (!this.registerForm.valid) {
    } else {
      this.loadObj = this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(true);
      const user = new tRegistrationInfo();
      user.email = this.registerForm.get('emailAddress').value;
      user.password = this.registerForm.get('password').value;
      const sanitisedUser = this.sanitiseDetails(user);
      this.authService.register(sanitisedUser).subscribe(
        (authResponse) => {
          this.loadObj = {
            ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
            loaded: true,
            loadedMessage: 'Success',
          };
          this.router.navigateByUrl(`/verify-email?email=${sanitisedUser.email}`)
        },
        (errResp) => {
          this.loadObj = {
            ...this.uiConfigService.initialiseLoadingBooleansWithLoadedMsg(false),
            loadError: true,
            // errorString: 'Access Denied',
          };

          this.error = true
          this.errorMessages = [errResp.error.error]
          console.warn(errResp.error.error);
        }
      );
    }
  }


}